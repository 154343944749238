.container {
    display: block;
    max-width: var(--block-max-width-text);
    height: auto;
    margin: 0 auto;

    .inner {
        position: relative;
        height: 0;
        padding-bottom: 150%;
    }

    .polaroid {
        position: absolute;
        top: 0;
        left: 0;
        width: 55%;
        max-width: none;
        margin: 0;
        opacity: 1;
        transition:
            transform 0.5s ease-out,
            opacity 0.2s ease-in;

        &:nth-child(2) {
            transition-delay: 0.2s;
        }

        &:nth-child(3) {
            transition-delay: 0.4s;
        }

        /* > * {
            animation-duration: 10s;
            animation-iteration-count: infinite;
            animation-name: move;
            transform-origin: 50% 50%;
        } */
    }

    &.hidden {
        .polaroid {
            opacity: 0;
            transform: rotate(0deg) scale(0.6) !important;
        }
    }

    &[data-layout='1'] {
        .inner {
            padding-bottom: 140%;
        }

        .polaroid:nth-child(1) {
            z-index: 3;
            top: 2%;
            left: 10%;
            transform: rotate(-12deg);
        }

        .polaroid:nth-child(2) {
            z-index: 2;
            top: 20%;
            left: 50%;
            transform: rotate(15deg);
        }

        .polaroid:nth-child(3) {
            z-index: 1;
            top: auto;
            bottom: 0;
            left: 0;
            transform: rotate(-6deg);
        }
    }

    &[data-layout='2'] {
        .inner {
            padding-bottom: 180%;
        }

        .polaroid {
            width: 70%;
        }

        .polaroid:nth-child(1) {
            z-index: 1;
            top: 0;
            right: 10%;
            left: auto;
            transform: rotate(8deg);
        }

        .polaroid:nth-child(2) {
            z-index: 2;
            top: 40%;
            left: 0%;
            transform: rotate(-19deg);
        }
    }

    &[data-layout='3'] {
        .inner {
            padding-bottom: 85%;
        }

        .polaroid {
            width: 60%;
        }

        .polaroid:nth-child(1) {
            z-index: 1;
            top: 0;
            left: -5%;
        }

        .polaroid:nth-child(2) {
            z-index: 2;
            top: 0;
            right: -5%;
            left: auto;
        }

        @media (--medium-viewport) {
            .polaroid:nth-child(1) {
                left: -10%;
            }

            .polaroid:nth-child(2) {
                right: -10%;
            }
        }
    }

    &[data-layout='4'] {
        .inner {
            padding-bottom: 145%;
        }

        .polaroid:nth-child(1) {
            z-index: 3;
            top: 2%;
            left: 2%;
            transform: rotate(-15deg);
        }

        .polaroid:nth-child(2) {
            z-index: 1;
            top: 20%;
            left: 50%;
            transform: rotate(16deg);
        }

        .polaroid:nth-child(3) {
            z-index: 2;
            top: 45%;
            left: 0;
            transform: rotate(-7deg);
        }
    }

    &[data-layout='5'] {
        .inner {
            padding-bottom: 160%;
        }

        .polaroid {
            width: 65%;
        }

        .polaroid:nth-child(1) {
            z-index: 1;
            top: 4%;
            left: 0;
            transform: rotate(15deg);
        }

        .polaroid:nth-child(2) {
            z-index: 2;
            top: auto;
            bottom: 2%;
            left: 30%;
            transform: rotate(-14deg);
        }
        /*
        &.hidden {
            .polaroid:nth-child(1) {
                transform: rotate(0deg) scale(0.6);
            }

            .polaroid:nth-child(2) {
                transform: rotate(0deg) scale(0.6);
            }
        } */
    }

    &[data-layout='6'] {
        .inner {
            padding-bottom: 190%;
        }

        .polaroid {
            width: 70%;
        }

        .polaroid:nth-child(1) {
            z-index: 1;
            top: 4%;
            left: 30%;
            transform: rotate(-14deg);
        }

        .polaroid:nth-child(2) {
            z-index: 2;
            top: auto;
            bottom: 3%;
            left: 0;
            transform: rotate(10deg);
        }

        /* &.hidden {
            .polaroid:nth-child(1) {
                transform: rotate(10deg) scale(0.6);
            }

            .polaroid:nth-child(2) {
                transform: rotate(-10deg) scale(0.6);
            }
        } */
    }
}



@keyframes move {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(2deg) skew(0, 2deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
